import { ApiSettings } from '@hultafors/shared/types';

import { authHeaders, getAuthEndpoint } from '../helpers';
import { parseAuthTokenResponse } from '../parse-auth-token-response';

/**
 * @desc Fetches Auth token for Parttrap API.
 * @param {object} settings
 * @return {object} {TODO describe}
 */
export const getAuthToken = async (settings: ApiSettings) => {
  let result: { error: boolean; data?: any } = { error: true };

  const headers = authHeaders(settings);
  const endpoint = getAuthEndpoint(settings);

  try {
    const response = await fetch(endpoint, headers);
    const jsonResponse = await response.json();
    const data = parseAuthTokenResponse(jsonResponse);

    result = { error: false, data };
  } catch (error) {
    console.error(error);
  }
  return result;
};
