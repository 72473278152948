import { ListApiResponse } from '@hultafors/shared/types';

import { getParametersFromUrl } from './helpers';
import { parsePrice } from './parse-price';
import { parseProductFilters } from './parse-product-filters';
import { ProductListItem, ToeguardProduct } from './types';

/**
 * @desc Parses products for list pages (and search result)
 * @param {object} productData
 * @returns
 */
export const productListParser = (
  productData: ListApiResponse<ProductListItem>,
): ListApiResponse<ToeguardProduct> => {
  const { items, ...rest } = productData;

  const resultItems = items.map((item) => {
    const product: ToeguardProduct = {
      productId: 0,
      productListId: 0,
      name: item.description ? item.description : item.name ?? '',
      isEsd: false,
      sku: item.sku ?? '',
      image: item.image ?? null,
      price: { value: 0, currency: '' },
      isNew: false,
      usp: [],
      icons: [],
    };

    const params = getParametersFromUrl(item.detailsPage?.url ?? '');

    if (params && params.length === 2) {
      product.productId = params[1] ?? 0;
      product.productListId = params[0] ?? 0;
    }

    product.price = parsePrice(item.price);

    if (item.attributes) {
      item.attributes.map((attr: any) => {
        switch (attr.name) {
          case 'New Flag in site':
            if (attr.values.length > 0) {
              product.isNew = true;
            }
            break;
          case 'SG_USP_Website_1':
            if (attr.values.length > 0) {
              const usp = attr.values.map(({ text }: any) => text);
              product.usp.push(...usp);
            }
            break;
          case 'TG Website ESD':
            if (attr.values.length > 0) {
              product.isEsd = true;
            }
            break;
          default:
            break;
        }
      });
    }
    return product;
  });

  return {
    ...rest,
    items: resultItems,
    filters: parseProductFilters(productData.filters),
  };
};
