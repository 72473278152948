import { ApiSettings } from '@hultafors/shared/types';

import { getData, getSearchEndpoint } from '../helpers';
import { constructFilterUrlParameter } from '../product-filter';
import { productListParser } from '../product-list-parser';

/**
 * @desc Fetches list of products based on search criteria
 * @param {string} searchValue
 * @param {boolean} includeFilters - Include product filters in request
 * @param {array} filters - Array of filters to filter the product result
 * @param {number} pageNumber - for paging through list
 * @param {number} pageSize - max number of products to fetch
 * @return {object} - Object with products array and paging data and optionally filter data
 */
export const searchProducts = async (
  settings: ApiSettings,
  token: any,
  searchValue: string,
  includeFilters = false,
  filters = [],
  pageNumber = 1,
  pageSize = 12,
) => {
  const SEARCH_SECTION_ID = 21;
  searchValue = searchValue.trim();
  let result: any = { error: true };
  try {
    const endpoint = getSearchEndpoint(settings, SEARCH_SECTION_ID);
    endpoint.searchParams.set('searchValue', searchValue);
    endpoint.searchParams.set('pageNumber', `${pageNumber}`);
    endpoint.searchParams.set('pageSize', `${pageSize}`);
    endpoint.searchParams.set(
      'includeFilter',
      includeFilters ? 'true' : 'false',
    );
    const filterParams = constructFilterUrlParameter(filters);
    filterParams.forEach((value: any, key: string) =>
      endpoint.searchParams.set(key, value),
    );

    result = await getData(settings, token, endpoint);
  } catch (error) {
    result.msg = error;
  }

  if (!result.error) {
    return productListParser(result);
  }
  return result;
};
