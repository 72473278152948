import { ApiSettings } from '@hultafors/shared/types';

import { getClearCacheEndpoint, getData } from '../helpers';

/**
 * @desc Clears api cache, mostly useful during development
 * @return void
 */
export const clearApiCache = async (settings: ApiSettings, token: any) => {
  if (settings) {
    const endpoint = getClearCacheEndpoint(settings);

    try {
      await getData(settings, token, endpoint);
    } catch (error) {
      console.error(error);
    }
  }
};
