/**
 * @desc Parses only values from getAuthToken that we need
 * @param {object} data response from getAuthToken
 * @returns
 */
export const parseAuthTokenResponse = (data: any) => {
  return {
    token: data.access_token,
    expires: new Date(data['.expires']),
    issued: new Date(data['.issued']),
  };
};
