import { FILTER_TYPE } from './product-filter';

/**
 * Helper to parse filters under children array with consolidated properties so we dont have to check filter types in view
 * @param {array} filters
 * @returns {array}
 */
export const parseProductFilters = (filters: any[] | null = []) => {
  if (!filters) {
    return [];
  }

  return filters.map((filter) => {
    const children = filter.values.map((child: any) => {
      const name
        = filter.type === FILTER_TYPE.ENUMERATED
          ? child.description
          : `${child.value} ${child.unit}`;
      const id
        = filter.type === FILTER_TYPE.ENUMERATED ? child.id : child.value;
      return {
        id,
        name,
        hits: child.hits,
        image: child.image,
        unit: child.unit,
        value: child.value,
      };
    });

    return {
      ...filter,
      name: filter.label,
      children,
      values: null,
      label: null,
    };
  });
};
