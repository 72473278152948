import { useEffect } from 'react';

import localFont from '@next/font/local';
import { GoogleTagManager, sendGTMEvent } from '@next/third-parties/google';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';

import { ParttrapApi } from '@hultafors/toeguard/api';
import { PageProps } from '@hultafors/toeguard/types';

const ApplicationProvider = dynamic(() =>
  import('@hultafors/toeguard/context').then((mod) => mod.ApplicationProvider),
);

const Footer = dynamic(() =>
  import('@hultafors/toeguard/components').then((mod) => mod.Footer),
);
const GlobalStyle = dynamic(() =>
  import('@hultafors/toeguard/components').then((mod) => mod.GlobalStyle),
);
const Nav = dynamic(() =>
  import('@hultafors/toeguard/components').then((mod) => mod.Nav),
);

const TimedDialog = dynamic(() =>
  import('@hultafors/toeguard/components').then((mod) => mod.TimedDialog),
);

const fontHelveticaNeue = localFont({
  src: [
    {
      path: '../fonts/HelveticaNeueLTPro-Roman.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../fonts/HelveticaNeueLTPro-Md.woff2',
      weight: '500',
      style: 'medium',
    },
    {
      path: '../fonts/HelveticaNeue-Bold.woff2',
      weight: '700',
      style: 'bold',
    },
  ],
  variable: '--font-helvetica-neue',
});

const fontTuskerGrotesk = localFont({
  src: '../fonts/TuskerGrotesk-6700Bold.woff2',
  variable: '--font-tusker-grotesk',
});

const CustomApp = ({ Component, pageProps, router }: AppProps<PageProps>) => {
  const { footer, global, maintenanceMode, menu, timedDialog, settings }
    = pageProps;
  const parttrapApi = new ParttrapApi({ settings });

  const onRouteChangeComplete = (page: string) => {
    sendGTMEvent({ event: 'pageview', page });
  };

  useEffect(() => {
    if (router.events) {
      onRouteChangeComplete('/');
      router.events.on('routeChangeComplete', onRouteChangeComplete);
    }
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [router.events]);

  const fonts = [fontHelveticaNeue.variable, fontTuskerGrotesk.variable].join(
    ' ',
  );

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
      </Head>
      <style jsx global>
        {`
        html,
        input,
        textarea,
        button,
        select {
          font-family: ${fontHelveticaNeue.style.fontFamily};
        }
      `}
      </style>

      <ApplicationProvider
        value={{
          footer,
          global,
          maintenanceMode,
          menu,
          timedDialog,
        }}
        settings={settings}
        parttrapApi={parttrapApi}
      >
        <div className={fonts}>
          <GlobalStyle />
          {global && <Nav />}
          <Component {...pageProps} />
          <GoogleTagManager gtmId="GTM-W5KDJSN" />
          {footer && <Footer />}
          {timedDialog?.formUrl && <TimedDialog timedDialog={timedDialog} />}
        </div>
      </ApplicationProvider>
    </>
  );
};

export default CustomApp;
