/**
 * @private Only public for unit tests
 * @desc Parse price object from PT studio
 * @param {object} priceItem
 * @returns {object} e.g { value: 666, currency: "EUR" }
 */
export const parsePrice = (priceItem: any) => {
  const price = { value: 0, currency: '' };
  if (priceItem) {
    if (priceItem.net && priceItem.net.min) {
      price.value = priceItem.net.min;
    }
    if (priceItem.currency && priceItem.currency.code) {
      price.currency = priceItem.currency.code;
    }
  }

  return price;
};
