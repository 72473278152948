/**
 * @desc The API have 2 filter types that sits on parent filter, all subsequent children inherit the same filter type
 */
export const FILTER_TYPE = {
  NUMERIC: 'numeric',
  ENUMERATED: 'enumerated',
};

const FILTER_KEYS = {
  PARENT: 'AttrId',
  NUMERIC: 'Value',
  ENUMERATED: 'ValueId',
};

/**
 * @desc Adds or removes filter to exising array of filters
 * @param {array} existingFilters
 * @param {object} parent, from api containing all children
 * @param {object} child, from api containg selected child
 * @returns {array}
 */
export const toggleProductFilters = ({
  existingFilters,
  parent,
  child,
}: {
  existingFilters: any[];
  parent: any;
  child: any;
}) => {
  let result: any[] = [];
  let children: any[] = [];

  if (!parent || !child) {
    return existingFilters;
  }

  if (existingFilters && existingFilters.length > 0) {
    const existingParent = existingFilters.find((x) => x.id === parent.id);
    if (existingParent) {
      children = existingParent.children.filter((x: any) => x.id !== child.id);

      if (existingParent.children.length > children.length) {
        // Removing a child filter
        if (existingParent.children.length === 1) {
          // Last child of parent, remove both parent and child
          result = existingFilters.filter((x) => x.id !== parent.id);
        } else {
          // Remove child from parent
          result = addChildren({
            existingFilters,
            parent: existingParent,
            children,
          });
        }
      } else {
        // adding a child filter to existing parent
        children = [...existingParent.children, { ...child }];
        result = addChildren({
          existingFilters,
          parent: existingParent,
          children,
        });
      }
    } else {
      // Current parent doesn´t exist, add new parent and child
      result = [...existingFilters, createParent({ parent, child })];
    }
  } else {
    // Filter is empty, add first parent and child
    result = [createParent({ parent, child })];
  }

  return result;
};

/**
 * @desc Helper function to add array of children to its parent and return an immutable array result
 * @param {array} existingFilters
 * @param {object} parent
 * @param {array} children
 * @returns {array}
 */
const addChildren = ({
  existingFilters,
  parent,
  children,
}: {
  existingFilters: any[];
  parent: any;
  children: any[];
}) => {
  const result: any[] = [];
  for (let i = 0, l = existingFilters.length; i < l; i++) {
    if (existingFilters[i].id === parent.id) {
      result.push({
        ...existingFilters[i],
        children,
      });
    } else {
      result.push({ ...existingFilters[i] });
    }
  }
  return result;
};

/**
 * @desc Helper to create structure of new parent node
 * @param {object} parent
 * @param {object} child
 * @returns
 */
const createParent = ({ parent, child }: { parent: any; child: any }) => {
  return {
    ...parent,
    children: [{ ...child }],
  };
};

/**
 * @desc Creates url parameter for the api from array of filters
 * @param {array} filters
 * @returns {string}
 */
export const constructFilterUrlParameter = (filters = []) => {
  // Filtering criterias are enumerated by array index notation.
  // Parent filter id is notated by AttrId and child by either
  // ValueId for enumerated filter types and Value for Numeric filters-
  // Each parent/child pair has to have their own index in f[x] array
  // f[0].AttrId=54&f[0].Value=100&f[1].AttrId=55&f[1].ValueId=200&f[2].AttrId=55&f[2].ValueId=201

  if (!filters || filters.length === 0) {
    return [];
  }

  const params = filters.length > 0 ? '&' : '';

  let filterIndex = 0;

  const result
    = filters.reduce((prevParent: any, currentParent: any) => {
      const valueKey
        = currentParent.type === FILTER_TYPE.NUMERIC
          ? FILTER_KEYS.NUMERIC
          : FILTER_KEYS.ENUMERATED;

      return currentParent.children.reduce(
        (prevChild: any, currentChild: any) => {
          const temp
            = prevChild
            + `f[${filterIndex}].${FILTER_KEYS.PARENT}=${currentParent.id}&f[${filterIndex}].${valueKey}=${currentChild.id}&`;
          filterIndex++;
          return temp;
        },
        prevParent,
      );
    }, params) ?? [];

  return result.slice(0, result.length - 1) ?? [];
};

/**
 * @desc Takes string from browser. Used on reload when user comes in to page with a link with a filter.
 * @param {string} filterString string from url
 * @returns {array}
 */
export const constructFilterFromUrl = (filterString: string) => {
  // ?page=1&f[0].AttrId=845&f[0].ValueId=579133&f[1].AttrId=846&f[1].ValueId=579134
  if (!filterString) {
    return [];
  }

  const result = [];

  const re = /&f\[\d+\]\./;

  // split url to array removing f[x] notation
  let filters = filterString.split(re);
  if (filters.length > 1) {
    // we have a product filter in url

    // remove page param
    filters = filters.slice(1, filters.length);

    let index = 0;

    while (index < filters.length) {
      const filterValues = filters[index]?.split('=');

      let filterObj: any = {};

      // ["AttrId", "666"]
      if (filterValues?.length === 2) {
        if (filterValues[0] === FILTER_KEYS.PARENT) {
          filterObj = {
            id: parseInt(filterValues[1] ?? ''),
            name: '',
            children: [],
          };

          if (filters.length >= index + 1) {
            const childValues = filters[index + 1]?.split('=');

            if (childValues?.length === 2) {
              // [ Value | ValueId ]
              if (childValues[0] === FILTER_KEYS.NUMERIC) {
                filterObj.type = FILTER_TYPE.NUMERIC;
              } else {
                filterObj.type = FILTER_TYPE.ENUMERATED;
              }
              filterObj.children = [
                { id: parseInt(childValues[1] ?? ''), name: '' },
              ];
            }
          }
        }
      }

      result.push(filterObj);
      // increment by 2 to move on to the next filter
      index += 2;
    }
  }

  return sortChildrenUnderParent(result);
};

/**
 * @desc Sort children under parent. Assuming children always comes after their parents as they do in the url.
 * @param {array} Flat list of filters
 * @return {array} - Array of parents with arrays of children
 */
const sortChildrenUnderParent = (filters: any[]) => {
  const results: any[] = [];

  filters.forEach((filter, index) => {
    let foundChild = false;
    if (index === 0) {
      results.push(filter);
    } else {
      for (const result of results) {
        if (filter.id === result.id) {
          if (filter.children.length === 1) {
            result.children.push(filter.children[0]);
          }
          foundChild = true;
          break;
        }
      }

      if (!foundChild) {
        // Add parent filter
        results.push(filter);
      }
    }
  });

  return results;
};

/**
 * @desc Pass querystring for parsing filters to array and page to separate values
 * @param {URLSearchParams} queryString
 * @returns {object} { page: x, filters: []}
 */
export const getPageAndFilter = (queryString: URLSearchParams) => {
  const result: { page: number; filters: any[] } = {
    page: 1,
    filters: [],
  };

  const page = parseInt(queryString.get('page') ?? '', 10);

  if (page && !isNaN(page)) {
    result.page = Number(page);
  }

  result.filters = constructFilterFromUrl(queryString.toString());

  return result;
};

/**
 * @desc Takes selectedFilters from url having only id:s but no names, take names from API call and set to selectedFilters
 * @param {array} productFilters, list of filters from API
 * @param {array} selectedFilters, list of filters from url
 * @returns
 */
export const resolveFilterNames = (
  productFilters: any[],
  selectedFilters: any[],
) => {
  let result = [];
  if (selectedFilters && productFilters) {
    result = selectedFilters.map((selectedFilter) => {
      const productParent = productFilters.find(
        (x) => x.id === selectedFilter.id,
      );
      if (productParent) {
        // Get names for child filters
        const children = selectedFilter.children.map((selectedChild: any) => {
          return productParent.children.find((productChild: any) => {
            if (selectedChild.id === productChild.id) {
              return {
                ...selectedChild,
                name: productChild.name,
              };
            }
          });
        });

        return {
          ...selectedFilter,
          children,
          name: productParent.name,
        };
      }
    });
  }

  return result;
};
