import { ApiSettings } from '@hultafors/shared/types';

import { getData, getProductEndpoint } from '../helpers';
import { parseSingleProduct } from '../parse-single-product';

/**
 * @desc Fetches single product
 * @param {number} productId
 * @param {number} productId
 * @return {object} - Product
 */
export const getProduct = async (
  settings: ApiSettings,
  token: any,
  productId: string,
) => {
  let result: any = { error: true };
  if (productId) {
    const endpoint = getProductEndpoint(settings);
    endpoint.searchParams.set('productId', productId);

    result = await getData(settings, token, endpoint);

    if (!result.error) {
      const product = parseSingleProduct(result.sections);
      product.id = productId;
      return {
        product: product,
      };
    }
  }

  return result;
};
