import { ApiSettings, ParttrapAuthToken } from '@hultafors/shared/types';

import { logEvent } from '../logging/logEvent';
import { telemetryErrors } from '../logging/telemetryErrors';

const getBase = (settings: ApiSettings): string => {
  const mode = process.env['NEXT_PUBLIC_PT_MODE'] || 'production';
  return `${settings.pt?.baseUrl}-${settings.market}-api-${mode}.parttrap.com/contentapi/2.0`;
};

const getBaseEndpoint = (settings: ApiSettings): URL => {
  return new URL(`${getBase(settings)}/${settings.pt?.siteId}/`);
};

export const getAuthEndpoint = (settings: ApiSettings): URL => {
  return new URL(`${getBase(settings)}/auth/token`);
};

export const getProductsEndpoint = (settings: ApiSettings): URL => {
  return new URL(
    `${getBaseEndpoint(settings)}sections/productslist/${
      settings.pt?.productList
    }`,
  );
};

export const getProductEndpoint = (settings: ApiSettings): URL => {
  return new URL(
    `${getBaseEndpoint(settings)}pages/productdetails/${
      settings.pt?.productList
    }`,
  );
};

export const getRelatedProductsEndpoint = (
  settings: ApiSettings,
  productRelationListId: string | number,
): URL => {
  return new URL(
    `${getBaseEndpoint(
      settings,
    )}sections/productrelationlist/${productRelationListId}`,
  );
};

export const getSearchEndpoint = (
  settings: ApiSettings,
  searchSectionId: string | number,
): URL => {
  return new URL(
    `${getBaseEndpoint(settings)}sections/searchlist/${searchSectionId}`,
  );
};

export const getClearCacheEndpoint = (settings: ApiSettings): URL => {
  return new URL(`${getBaseEndpoint(settings)}layout/clearcache?cacheType=0`);
};

/**
 * @desc Construct header for all Parttrap studio requests. Do not use OHC_API_USER from process.env as it changes between markets
 * @param object settings - All api settings
 * @return object - Request headers
 */
export const getHeaders = (
  settings: ApiSettings,
  token: ParttrapAuthToken,
): RequestInit => {
  return {
    headers: {
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'x-api-key': settings.pt?.user ?? '',
      'Authorization': `Bearer ${token.token}`,
    },
    method: 'GET',
  };
};

/**
 * @desc Construct headers for getAuthToken fetch
 */
export const authHeaders = (settings: ApiSettings): RequestInit => {
  // Lang specific token within a market uses api user and lang e.g "hellbergsafety-fr"
  const client_secret = settings.pt?.lang
    ? `${settings.pt?.user}-${settings.pt?.lang}`
    : settings.pt?.user;
  const params = {
    grant_type: 'client_credentials',
    pt_site_id: settings.pt?.siteId,
    client_secret,
  };

  return {
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: urlEncodedObject(params),
  };
};

const urlEncodedObject = (obj: any) =>
  Object.keys(obj)
    .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(obj[k]))
    .join('&');

/**
 * @desc Add parameters when constructing url to API
 * @param {string} url https://api.com?name=John
 * @return {array} - array of values from query params
 */
export const getParametersFromUrl = (url: string) => {
  if (!url) {
    return null;
  }
  return (
    url
      .split('/')
      ?.pop()
      ?.match(/^\d+|\d+\b|\d+(?=\w)/g) ?? []
  );
};

/**
 * @desc Get query parameter by key
 * @param {string} url http://api.com?name=John
 * @param {string} parameterName name
 * @return {string} - value "John"
 */
export const getParameterFromUrlByName = (
  url: string,
  parameterName: string,
): string => {
  let result: any = '';
  if (url && parameterName) {
    const searchParams = new URLSearchParams(url.split('?')[1]);
    result = searchParams.get(parameterName);
  }
  return result;
};

/**
 * @desc Internal helper function used by all PT endpoints to fetch data and set request status
 * @param {string} endpoint
 * @return {object}
 */
export const getData = async (
  settings: ApiSettings,
  token: ParttrapAuthToken,
  endpoint: URL,
) => {
  let result: any = null;
  let response: any = null;
  let error: any = null;
  try {
    const apiHeaders = getHeaders(settings, token);

    response = await fetch(endpoint, apiHeaders);
  } catch (ex) {
    error = Object.prototype.hasOwnProperty.call(ex, 'message')
      ? (ex as any).message
      : ex;
  }

  if (response && response.status === 200) {
    result = {
      data: response,
    };
  } else {
    result = { error: true, status: response?.status };
    logError(settings, response, endpoint.toString(), error);
  }

  if (!result.error) {
    result = await result?.data?.json();
  }
  return result;
};

const logError = (
  settings: ApiSettings,
  response: any,
  endpoint: string,
  error: any,
) => {
  // json data describing the error
  const metaData: any = {
    endpoint,
  };
  if (response) {
    metaData.statusCode = response.status;
    metaData.error = response.statusText ? response.statusText : 'Unknown';
  } else {
    metaData.error = error;
  }
  logEvent(settings, telemetryErrors.PT_API_ERROR, metaData);
};

/**
 * @desc Check if the token is of right format and whether it´s expired or not
 * @param {object} token
 * @returns {boolean}
 */
export const isTokenValid = (tokenObj: any) => {
  if (!tokenObj) {
    return false;
  }
  if (tokenObj.token && tokenObj.expires) {
    const now = new Date().getTime();

    const expires = new Date(tokenObj.expires).getTime();

    if (now < expires) {
      const minTimeBeforeExpire = 3600000; // 1hour in ms
      const timeBeforeExpire = expires - now;

      // If token expires within MIN_TIME_BEFORE_REFRESH, refresh it anyway
      if (minTimeBeforeExpire < timeBeforeExpire) {
        return true;
      }
    }
  }

  return false;
};
