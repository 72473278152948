import {
  ProductDetailsApiDocument,
  ProductDetailsApiImage,
  ProductDetailsApiSection,
  ProductDetailsAttribute,
} from '@hultafors/shared/types';

import { parsePrice } from './parse-price';
import { ToeguardProductDetails, ToeguardProductDetailsBase } from './types';

export const parseSingleProduct = (
  productData: ProductDetailsApiSection[],
): ToeguardProductDetails => {
  const parsedProduct: ToeguardProductDetails = {
    id: '',
    name: '',
    sku: '',
    images: [],
    documents: [],
    usp: [],
    isNew: false,
    price: { value: 0, currency: '' },
    intro: '',
    details: '',
    isEsd: false,
  };

  if (!productData) {
    // Return empty
    return parsedProduct;
  }

  productData.forEach(({ system: { type, codename }, data }) => {
    const attributes: ProductDetailsAttribute[] = data?.attributes ?? [];
    const images: ProductDetailsApiImage[] = data?.images ?? [];
    const documents: ProductDetailsApiDocument[] = data?.documents ?? [];

    switch (type) {
      case 'product_detail_attributes':
        if (codename) {
          switch (codename) {
            case 'new_flag':
              parsedProduct.isNew = !!attributes.length;
              break;
            case 'esd':
              parsedProduct.isEsd = !!attributes.length;
              break;
            case 'usp':
              parsedProduct.usp
                = attributes
                  .map((uspItem: any) => {
                    if (uspItem.values.length) {
                      return uspItem.values[0].text;
                    }
                  })
                  .filter(Boolean) ?? [];
              break;

            default:
              parsedProduct[codename as keyof ToeguardProductDetailsBase]
                = attributes?.map((attr) => {
                  return {
                    name: attr.name,
                    values: attr.values,
                  };
                }) ?? [];
          }
        }
        break;
      case 'product_descr':
        if (codename === 'name') {
          parsedProduct.name = data?.value ?? '';
        }
        if (codename === 'intro') {
          parsedProduct.intro = data?.value ?? '';
        }
        if (codename === 'details') {
          parsedProduct.details = data?.value ?? '';
        }
        break;
      case 'product_sku_number':
        parsedProduct.sku = data?.sku ? data.sku : '';
        break;
      case 'product_price':
        parsedProduct.price = parsePrice(data);
        break;
      case 'product_image_library':
        if (images.length) {
          parsedProduct.images.push(...images);
        }
        break;
      case 'product_documents':
        if (documents.length) {
          parsedProduct.documents = documents;
        }
        break;
      default:
    }
  });

  return parsedProduct;
};
