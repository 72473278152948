import { ApiSettings } from '@hultafors/shared/types';

import { getData, getProductsEndpoint } from '../helpers';
import { constructFilterUrlParameter } from '../product-filter';
import { productListParser } from '../product-list-parser';

/**
 * @desc Fetches list of products
 * @param {number} productCatalogNodeId
 * @param {boolean} includeFilters - Include product filters in request
 * @param {array} filters - Array of filters to filter the product result
 * @param {number} pageNumber - for paging through list
 * @param {number} pageSize - max number of products to fetch
 * @return {object} - Object with products array, paging data and optionally filters
 */
export const getProducts = async (
  settings: ApiSettings,
  token: any,
  productCatalogNodeId: string | number,
  includeFilters = true,
  filters = [],
  pageNumber = 1,
  pageSize = 12,
) => {
  if (productCatalogNodeId) {
    const endpoint = getProductsEndpoint(settings);
    endpoint.searchParams.set(
      'productCatalogNodeId',
      `${productCatalogNodeId}`,
    );
    endpoint.searchParams.set('pageNumber', `${pageNumber}`);
    endpoint.searchParams.set('pageSize', `${pageSize}`);
    endpoint.searchParams.set(
      'includeFilter',
      includeFilters ? 'true' : 'false',
    );
    const filterParams = constructFilterUrlParameter(filters);
    filterParams?.forEach((value: string, key: string) =>
      endpoint.searchParams.set(key, value),
    );
    const data = await getData(settings, token, endpoint);

    return productListParser(data);
  }
};
