import { ApiSettings } from '@hultafors/shared/types';

import { getData, getRelatedProductsEndpoint } from '../helpers';
import { productListParser } from '../product-list-parser';

/**
 * @desc Fetches list of products related to a specific product
 * @param {number} productId
 * @param {number} sectionId
 * @param {number} pageNumber - for paging through list
 * @param {number} pageSize - max number of products to fetch
 * @return {object} - Object with products array and paging data
 */
export const getRelatedProducts = async (
  settings: ApiSettings,
  token: any,
  productId: string,
  sectionId: string,
  pageNumber: number,
  pageSize: number,
) => {
  let result: any = { error: true };

  if (productId && sectionId) {
    const endpoint = getRelatedProductsEndpoint(settings, sectionId);
    endpoint.searchParams.set('productId', productId);
    endpoint.searchParams.set('pageNumber', `${pageNumber}`);
    endpoint.searchParams.set('pageSize', `${pageSize}`);

    try {
      result = await getData(settings, token, endpoint);
    } catch (error) {
      result.msg = error;
    }

    if (!result.error) {
      return productListParser(result);
    }
  }
  return result;
};
