import { ApiSettings } from '@hultafors/shared/types';

import { logEvent } from '../logging/logEvent';

import { isTokenValid } from './helpers';
import { clearApiCache } from './queries/clearCache';
import { getAuthToken } from './queries/getAuthToken';
import { getProduct } from './queries/getProduct';
import { getProducts } from './queries/getProducts';
import { getRelatedProducts } from './queries/getRelatedProducts';
import { searchProducts } from './queries/searchProducts';

interface ParttrapApiParameters {
  settings: ApiSettings;
  appInsights?: any;
}

export class ParttrapApi {
  tokenCache = null;
  settings: ApiSettings;
  appInsights: any;
  auth: any = null;

  constructor({ settings, appInsights = undefined }: ParttrapApiParameters) {
    this.settings = settings;
    this.appInsights = appInsights;
  }

  /**
   * @desc Singleton function trying first to get authToken from state then from local storage and finally fetching a new token if not found or invalid (e.g it has expired )
   * @returns {object} authToken
   */
  async getAuthTokenHelper() {
    let token = null;

    if (this.tokenCache) {
      // we have token in state, if valid return and use this
      if (isTokenValid(this.tokenCache)) {
        return this.tokenCache;
      }
    }
    // if (USE_TOKEN_STORAGE) {
    //   // try to find token in local storage
    //   token = getTokenFromStorage();
    // }
    if (!isTokenValid(token)) {
      const result: any = await getAuthToken(this.settings);
      // If we have an error here we have a big problem
      if (!result.error) {
        token = result.data;

        // if (USE_TOKEN_STORAGE) {
        //   setTokenInStorage(token);
        // }
      }
    }

    // set token either from local storage or api in local state
    this.tokenCache = token;
    return token;
  }

  // Parttrap API

  getProducts = async (
    productCatalogNodeId: string,
    includeFilters = true,
    filters = [],
    pageNumber = 1,
    pageSize = 12,
  ) => {
    const token = await this.resolveToken();

    return getProducts(
      this.settings,
      token,
      productCatalogNodeId,
      includeFilters,
      filters,
      pageNumber,
      pageSize,
    );
  };

  getProduct = async (productId: string) => {
    const token = await this.resolveToken();
    return getProduct(this.settings, token, productId);
  };

  getRelatedProducts = async (
    productId: string,
    sectionId: string,
    pageNumber = 1,
    pageSize = 4,
  ) => {
    const token = await this.resolveToken();
    return getRelatedProducts(
      this.settings,
      token,
      productId,
      sectionId,
      pageNumber,
      pageSize,
    );
  };

  searchProducts = async (
    searchValue: string,
    includeFilters = false,
    filters = [],
    pageNumber: number,
    pageSize: number,
  ) => {
    const token = await this.resolveToken();
    return searchProducts(
      this.settings,
      token,
      searchValue,
      includeFilters,
      filters,
      pageNumber,
      pageSize,
    );
  };

  clearCache = async () => {
    const token = await this.resolveToken();
    clearApiCache(this.settings, token);
  };

  /**
   * @desc Helper that fetches Auth token and merge with current settings
   * @returns settings object with an auth token
   */
  resolveToken = async () => {
    return await this.getAuthTokenHelper();
  };

  // App insights log

  logError = (telemetryError: any, data = null) => {
    logEvent(this.settings, telemetryError, data);
  };
}
