/**
 * @desc Logs event to Azure App Insights
 * @param {object} settings Api settings with appInsights object when in production mode
 * @param {string} telemetryError of error from the telemetryErrors definition
 * @param {object} metaData of strings/numbers with metadata about the error, ex {endpoint: "https://...", statusCode: 400, cause: "Exception in function B"}
 * Always make sure to pass endpoint or current page depending on use for easier debugging
 */
export const logEvent = async (settings, telemetryError, metaData) => {
  if (settings.appInsights && telemetryError) {
    settings.appInsights.trackEvent({
      name: telemetryError,
      properties: metaData ? metaData : {},
    });
  }
};
